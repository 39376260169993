import React, { useState } from 'react';
//import { useNavigate} from 'react-router-dom';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress'

import config from '../config/config.js';
import logo from '../imgs/laboralogo.png';
import fotoPadrao from '../imgs/user.png';
import '../Style.css';

import Login from './Login';
import Time from "./Time";
import PasswordTemporario from './PasswordTemporario';
import PasswordUpdate from './PasswordUpdate';
import Modal from './ModalPage';

function Checkpoin(){

	//let navigate = useNavigate(); 
	//const routeToHome = () => navigate('/');
//	const urlUserStatus = `${config.baseUrl}/${config.resrc.status}`;
//	const urlUserNextCheckpoint = `${config.baseUrl}/${config.resrc.nextCheckpoint}`;
	const urlCheckpointStatus = `${config.baseUrl}/${config.resrc.checkpointStatus}`;
	const urlFormCorrecao = `${config.baseUrl}/${config.resrc.correcao}`;
	const urlCreatCheckpoint = `${config.baseUrl}/${config.resrc.creatcheckpoint}`;

	const [state, setState] = useState({ loading: false, show: false, msg: '', type: 'info' });
	const hideModal = () => {
		setState({ show: false, loading: false })
		if(state.type === 'error') logOut();
		if(state.type === 'success'){
			console.log('Recarregando a pagina.');
			window.location.reload();
			//getUserStatus();
		}
	};

	const userLoginDefault = {
		email: '',
		auth: false,
		token: null,	//'',
		userId: null,	//'',
		fullName: '',
		agency: [],
		photo: '',
		tempPassword: false,
		updatePassword: false,
	}
	let [userLogin, setUserData] = useState(userLoginDefault);
	function getUserData(){return userLogin}

	const userStatusDefault = {
		id: null,	//'',
		name: '',
		email: '',
		photo: '',
		agency: [],
		checkpoint: {
			type: '',
			tipo: '',
		},
	};
	let [userStatus, setUserStatus] = useState(userStatusDefault);

	const formCorrecaoDefault = {
		embarque: '',
		desembarque: '',
		isLoad: false,
	}
	let [formCorrecao, setFormCorrecao] = useState(formCorrecaoDefault);

	let [updatePassword, setUpdatePass] = useState();

	const dataToSendDefault = {
		userId: '',
		createdAt: '',
		type: 2,
		agency: '',
		formId: '',
		answer: '',
	}
	let [dataToSend, setDataToSend] = useState(dataToSendDefault);

	function getUserStatus(){
		axios.get(urlCheckpointStatus, {headers: {'x-access-token': userLogin.token}}).then(response => {
			if(response.status === 200){
				let data = {
					id: response.data.user.id,
					name: response.data.user.full_name,
					email: response.data.user.email,
					photo: response.data.user.photo,
					agency: response.data.user.agency,
					checkpoint: {
						tipo: response.data.checkpoint.next,
        				type: response.data.checkpoint.type
					}
				}
				if(response.data.checkpoint.type === 1) data.agency = [response.data.checkpoint.agency];
				//console.log('data:');
				//console.log(data);
				setUserStatus(data);
				setDataToSend({userId: response.data.user.id});
				//setState({ loading: false });
			}else{
				setState({ loading: false, show: true, type: 'warning', msg: `Status: ${response.status}.\nUsuario ou Senha invalidos.` });
			}
		})
		.catch(() => {
			setState({ loading: false, show: true, type: 'error', msg: `Falha do servidor.` });
		});

/*		axios.get(urlUserStatus, {headers: {'x-access-token': userLogin.token}}).then(response => {
			//console.log(response.data);
			if(response.status === 200){
				let data = {
					id: response.data.user._id,
					name: response.data.user.fullName,
					email: response.data.user.email,
					photo: response.data.user.photo,
					agency: response.data.user.agency,
					checkpoint: {}
				}
				//console.log('data 1:');
				//console.log(data);
				//setUser(data);
				//setDataToSend({userId: response.data.user._id});
				const userId = response.data.user._id;
			//-- get Last Checkpoint. --------------------------//
				axios.get(urlUserNextCheckpoint, {headers: {'x-access-token': userLogin.token}})
					.then(response => {
						console.log(data.agency);
						data.checkpoint.type = response.data.type;
						data.checkpoint.tipo = response.data.nextCheckpoint;
						if(response.data.type === 1) data.agency = [response.data.agency];
						console.log('data 2a:');
						console.log(data.agency);
						setUserStatus(data);
						setDataToSend({userId: userId});
					})
					.catch(() => {
						console.log(`Status: ${response.status}. -_-`);
					});
			//--------------------------------------------------//
			}else{
				//console.log(`Status: ${response.status}, Usuario ou Senha invalidos.`);
				alert(`Status: ${response.status}.\nUsuario ou Senha invalidos.`);
			}
		})
		.catch(() => {
			//console.log(`Erro: Falha do servidor.`);
			alert(`Erro: Falha do servidor.`);
		});
*/
	}

	function getForms(){
		console.log(`Pegando os Forms de Correção para Embarque e Desembarque.`);
		axios.get(urlFormCorrecao, {headers: {'x-access-token': userLogin.token}}).then(response => {
			if(response.status === 200){
				const data = {
					embarque: response.data.embarque,
					desembarque: response.data.desembarque,
					isLoad: true,
				}
				setFormCorrecao(data);
				//console.log(data);
			}else{
				//alert(`Status: ${response.status}.\nUsuario ou Senha invalidos.`);
				setState({ loading: false, show: true, type: 'warning', msg: `Status: ${response.status}.\nUsuario ou Senha invalidos.` });
			}
		})
		.catch(() => {
			//console.log(`Erro: Falha do servidor.`);
			setState({ loading: false, show: true, type: 'error', msg: `Falha do servidor.` });
		});
	}

	function setCheckpoint(){
		axios.post(urlCreatCheckpoint, dataToSend, {headers: {'x-access-token': userLogin.token}}).then(response => {
				//console.log(`Status: ${response.status} [CHECKPOINT].`);
				//console.log(response.data);
				if(response.status === 201){
					if(response.data.type === 0){
						//alert(`Embarque efetuado com sucesso.`);
						setState({ loading: false, show: true, type: 'success', msg: `Embarque efetuado com sucesso.` });
					}else{
						//alert(`Desembarque efetuado com sucesso.`);
						setState({ loading: false, show: true, type: 'success', msg: `Desembarque efetuado com sucesso.` });
					}
				}else{
					//console.log(`Status: ${response.status}, Usuario ou Senha invalidos.`);
					setState({ loading: false, show: true, type: 'warning', msg: `Status: ${response.status}.\nUsuario ou Senha invalidos.` });
				}
			})
			.catch(() => {
				//console.log(`Erro: Falha do servidor.`);
				setState({ loading: false, show: true, type: 'error', msg: `Falha do servidor.` });
			});
	}

	function logOut(){
		console.log('Efetuando Logout.');
		setUserData(userLoginDefault);
		setUserStatus(userStatusDefault);
		setFormCorrecao(formCorrecaoDefault);
		updatePassword = false;
		setDataToSend(dataToSendDefault);
	}

	function handleInputChange(event){
		dataToSend[event.target.name] = event.target.value;
		setDataToSend(dataToSend);
		console.log(dataToSend);
	}

	function handleInputChangeCheckpoint(event){
		let idForm = '';
		let tipo = 0;
		//if((event.target.value -1) === 0){
		if((event.target.value) === 0){
			tipo = 0;
			idForm = formCorrecao.embarque;
			console.log(`Embarque Id: ${idForm}`);
		}else{
			//if((event.target.value -1) === 1){
			if((event.target.value) === 1){
				tipo = 1;
				idForm = formCorrecao.desembarque;
				console.log(`Desembarque Id: ${idForm}`);
			}else{
				tipo = 2;
				idForm = '';
				console.log(`Tipo não selecionado`);
			}
		}
		dataToSend[event.target.name] = tipo;
		dataToSend['formId'] = idForm;
		setDataToSend(dataToSend);
		console.log(dataToSend);
	}

	function goToupdatePassword(){
		//console.log('Vai para Update password!');
		setUpdatePass(true);
	}

	function handleFormSubmit(event){
		event.preventDefault();
		let data = new Date();
		dataToSend['type'] = userStatus.checkpoint.type;

		let idForm = '';
		if((userStatus.checkpoint.type) === 0){
			idForm = formCorrecao.embarque;
			console.log(`Embarque Id: ${idForm}`);
		}else{
			if((userStatus.checkpoint.type) === 1){
				idForm = formCorrecao.desembarque;
				console.log(`Desembarque Id: ${idForm}`);
			}else{
				idForm = '';
				console.log(`Tipo não selecionado`);
			}
		}
		dataToSend['formId'] = idForm;

		dataToSend['createdAt'] = data.toISOString();
		setDataToSend(dataToSend);
		console.log('dataToSend:');
		console.log(dataToSend);
		//verifica se todos os dados estão completos.
		if((dataToSend.userId === undefined) || (dataToSend.userId === '') || (dataToSend.userId === null)){
			setState({ loading: false, show: true, type: 'warning', msg: `Usuário inválido.\nTente recarregar a página.` });
		}else	if((dataToSend.type === undefined) || (dataToSend.type < 0) || (dataToSend.type > 1)){
			setState({ loading: false, show: true, type: 'warning', msg: `Selecione Embarque ou Desembarque.` });
		}else	if((dataToSend.formId === undefined) || (dataToSend.formId === '')  || (dataToSend.formId === null)){
			setState({ loading: false, show: true, type: 'warning', msg: `Dados inválidos.\nTente recarregar a página.` });
		}else	if((dataToSend.agency === undefined) || (dataToSend.agency === '') || (dataToSend.agency === '0') || (dataToSend.agency === null)){
			setState({ loading: false, show: true, type: 'warning', msg: `Selecione o Workplace.` });
		}else{
			if((dataToSend.answer === undefined) || (dataToSend.answer === null)) dataToSend.answer = '';
			console.log(dataToSend);
			setState({ loading: true });
			setCheckpoint();
		}
	}

	if(!userLogin.token || userLogin.token === null || userLogin.token === '') {
		//console.log('Usuário não Logado! Token: ', userLogin.token);
		return (<div><Login setCampos={setUserData}/></div>);
	}
	else{
		//console.log('Usuário Logado!', userLogin.tempPassword);
		if(userLogin.tempPassword){
			//console.log(`Password temporario ${userLogin.tempPassword}.`);
			return (<div><PasswordTemporario setCampos={setUserData} getCampos={getUserData} /></div>);
		}else{
			//console.log(`User: ${userStatus.id}, isLoad: ${formCorrecao.isLoad}, token: ${userLogin.token}.`);
			if(!userStatus.id){
				//console.log(`Buscando o Status do usuário: ${userLogin.fullName}.`);
				getUserStatus();
			}else{
				//console.log(userStatus);
				if(!formCorrecao.isLoad) getForms();
			}
			if((!userStatus.id) && (!formCorrecao.isLoad) && (!state.loading)) setState({ loading: true });
			if((userStatus.id) && (formCorrecao.isLoad) && (state.loading)) setState({ loading: false });
			if(updatePassword) return (<div><PasswordUpdate setUpdatePass={setUpdatePass} setCampos={setUserData} getCampos={getUserData} /></div>);
		}
	}

	if(state.loading) return (<div><CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%' }}/></div>);

	return (
		<div className="container">
			<img src={logo} className="Labora-logo" alt="logo" />
			<h3>"Bem vindo a Labora!"</h3>

			<div>
				<img src={userStatus.photo || fotoPadrao} alt="logo" className="foto-profile" />
			</div>

			<form className='form' onSubmit={handleFormSubmit}>
				<fieldset>
					<legend> <h4>Efetuar Embarque/Desembarque:</h4> </legend>
					
					<div>
						<label htmlFor="nome">Nome:</label>
						<input className='font' type="text" id="nome" name="nome" placeholder="Nome do usuário.." value={userStatus.name || ''} disabled />

						<label htmlFor="e-mail">E-mail:</label>
						<input className='font' type="text" id="email" name="email" placeholder="E-mail do usuário.." value={userStatus.email || ''} disabled />

						<label htmlFor="checkpoint">Embarque/Desembarque:</label>
						<select className='font' type="text" id="type" name="type" placeholder="Selecione..." onChange={handleInputChangeCheckpoint} disabled >
							{/* <option value="0">Selecione uma opção</option> */}
							{/* <option value="1">Embarque</option> */}
  							{/* <option value="2">Desembarque</option> */}
							<option value={userStatus.checkpoint.type || ''}>{`${userStatus.checkpoint.tipo}` || ''}</option>
						</select>

						<label htmlFor="agency">Workplace:</label>
						<select className='font' type="text" id="agency" name="agency" placeholder="Selecione..." onChange={handleInputChange} >
								<option value="0">Selecione uma opção</option>
								{userStatus.agency.map(workplace => 
								(<option key={workplace._id} value={workplace._id}>{`[${workplace.companyId.name} - ${workplace.number}] ${workplace.address}`}
								</option>))}
						</select>

						<Time />

						<label htmlFor="answer">Observação:</label>
						<textarea id="answer" name="answer" placeholder="Qual sua motivação hoje ?" className="textArea" onChange={handleInputChange}></textarea>
					</div>
					<br/>

					<div className='inline'>
						<input className='red-button' type="button" value="Sair" onClick={logOut} />
						<input className='green-button' type="submit" value="Salvar" />
					</div>

					<div className='center'>
						<a className='clicavel' onClick={goToupdatePassword} >Alterar Senha</a>
					</div>
					{/* <br/> */}
					{/* <br/> */}

				</fieldset>
			</form>

			<Modal modal={state} handleClose={hideModal} />

		</div>
	);
}

export default Checkpoin;
