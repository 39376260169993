
module.exports = {
	//baseUrl: 'http://127.0.0.1:3333',
	//baseUrl: 'https://devappvarejo.labora.tech',
	//baseUrl: 'https://appvarejo.labora.tech/api',
	baseUrl: 'https://userapi3.labora.tech',
	resrc: {
		login: 'user/login',
		status: 'user/status',
		correcao: 'form/correcao',
		
		creatcheckpoint: 'checkpoint',
		nextCheckpoint: 'checkpoint/next',
		checkpointStatus: 'checkpoint/status',

		getUser: 'user',
		resetPassword: 'user/resetPassword',
		updateTempPass: 'user/updateTempPassword',
		updatePassword: 'user/updatePassword',
	},
}
