import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

//import logo from './logo.svg';
//import './App.css';

import Home from './pages/Home';
import Login from './pages/Login';
import Checkpoint from './pages/Checkpoint';
import PasswordTemporario from './pages/PasswordTemporario';
import Time from './pages/Time';

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Checkpoint />} />

				{/* <Route path="/" element={<Home />} /> */}
				{/* <Route path="/" element={<Login />} /> */}
				{/* <Route path="/Checkpoint" element={<Checkpoint />} /> */}
				{/* <Route path="/login" element={<Login />} /> */}
				{/* <Route path="/" element={<Time />} /> */}
			</Routes>
		</BrowserRouter>
	);
}

export default App;
