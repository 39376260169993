import React, { useState } from 'react';
//import { useNavigate} from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress'

import config from '../config/config.js';
import logo from '../imgs/laboralogo.png';
import '../Style.css';
import Modal from './ModalPage';

export default function PasswordUpdate ({ setUpdatePass, setCampos, getCampos }) {

	const urlUpdatePassword = `${config.baseUrl}/${config.resrc.updatePassword}`;
	const [state, setState] = useState({ loading: false, show: false, msg: '', type: 'info' });
	const hideModal = () => {
		setState({ show: false, loading: false })
		if(state.type === 'success') setUpdatePass(false);
	};

	const [oldPassword, setOldPassword] = useState();
	const [newPassword, setNewPassword] = useState();
	const [confNewPass, setConfNewPass] = useState();

	function handleFormSubmit(event){
		event.preventDefault();
		setState({ loading: true });
		//console.log('newPassword: ', newPassword);
		if((newPassword !== '') && (newPassword !== null) && (newPassword !== undefined) && (newPassword === confNewPass)){
			const uData = getCampos();
			const data = {oldPassword: oldPassword, newPassword: newPassword};
			axios.put(urlUpdatePassword, data, {headers: {'x-access-token': uData.token}}).then(response => {
				setState({ loading: false, show: true, type: 'success', msg: `Senha alterada com sucesso.` });
			})
			.catch((error) => {
				setState({ loading: false, show: true, type: 'warning', msg: `Senha atual incorreta.` });
			});
		}else{
			setState({ loading: false, show: true, type: 'warning', msg: `Confira se a nova senha ou a confirmação da senha foi digitada incorretamente.` });
		}
	}

	function cancelar(event){
		event.preventDefault();
		setUpdatePass(false);
	}

	if(state.loading) return (<div><CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%' }}/></div>);

	return (
		<div className="container">
			<img src={logo} className="Labora-logo" alt="logo" />
			<h4>"Alterar Senha!"</h4>

			<form className='form' onSubmit={handleFormSubmit} >
				<fieldset>
					<legend className='form'> <h4>Defina Nova Senha:</h4> </legend>

					<div>
						<label htmlFor="senha">Senha Atual:</label>
						<input type="password" id="passwordA" name="passwordA" placeholder="Senha Atual..." onChange={e => setOldPassword(e.target.value)} />
						<label htmlFor="senha">Nova Senha:</label>
						<input type="password" id="passwordN" name="passwordN" placeholder="Nova Senha..." onChange={e => setNewPassword(e.target.value)} />
						<label htmlFor="senha">Confirmação Nova Senha:</label>
						<input type="password" id="passwordC" name="passwordC" placeholder="Confirmação Nova Senha..." onChange={e => setConfNewPass(e.target.value)} />
					</div>
					<br/>

					<div className='inline'>
						<input className='red-button' type="button" value="Cancelar" onClick={cancelar} />
						<input className='green-button' type="submit" value="Salvar" />
					</div>
					{/* <br/>
					<br/> */}

				</fieldset>
			</form>
			<Modal modal={state} handleClose={hideModal} />
		</div>
	);
}

PasswordUpdate.propTypes = {
	setUpdatePass: PropTypes.func.isRequired,
	setCampos: PropTypes.func.isRequired,
	getCampos: PropTypes.func.isRequired
};

