import React, { useState } from 'react';
//import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress'

import config from '../config/config.js';
import logo from '../imgs/laboralogo.png';
import '../Style.css';
import Modal from './ModalPage';

function PasswordReset({ resPassword }){

	const [state, setState] = useState({
		loading: false,
		resetPass: false,
		show: false,
		msg: '',
		type: 'info'
	});
	const hideModal = () => {
		setState({ show: false, loading: false });
		if(state.type === 'success') cancelar();
	};

	const urlResetPassword = `${config.baseUrl}/${config.resrc.resetPassword}`;
    const [email, setEmail] = useState();

	function handleFormSubmit(event){
		event.preventDefault();
		setState({ loading: true });
		if(email){
			axios.post(urlResetPassword, { email: email }).then(response => {
				//console.log(response.data);
				if(response.status === 200)
					//alert(`Atenção\nCaso o email esteja cadastrado uma nova senha será enviada.`);
					setState({ loading: false, show: true, type: 'success', msg: `Atenção\nCaso o email esteja cadastrado uma nova senha será enviada.` });
					//resPassword({ resetPass: false });
				else
					setState({ loading: false, show: true, type: 'warning', msg: `Erro: Não foi passivel enviar a requisição.` });
			})
			.catch((err) => {
				//console.log(err);
				//alert(`Erro: Não foi passivel enviar a requisição.`);
				setState({ loading: false, show: true, type: 'warning', msg: `Erro: Não foi possível enviar a requisição.` });
			});
		}else{
			//alert('Erro: forneça um endereço de email.');
			setState({ loading: false, show: true, type: 'warning', msg: 'Erro: forneça um endereço de email.' });
		}
	}

	function cancelar(){
		resPassword({ resetPass: false });
	}

	if(state.loading) return (<div><CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%' }}/></div>);

	return (
		<div className="container">
			<img src={logo} className="Labora-logo" alt="logo" />
			<h4>"Redefinir a Senha!"</h4>

			<form className='form' onSubmit={handleFormSubmit} >
				<fieldset>
					<legend> <h4>Pedir nova Senha:</h4> </legend>

					<div>
						<label htmlFor="e-mail">E-mail:</label>
						<input type="text" id="email" name="email" placeholder="E-mail do usuário.." onChange={e => setEmail(e.target.value)} />
					</div>
					<br/>

					<div className='inline'>
						<input className='red-button' type="button" value="Cancelar" onClick={cancelar} />
						<input className='green-button' type="submit" value="Enviar" />
					</div>
					{/* <br/>
					<br/> */}

				</fieldset>
			</form>
			<Modal modal={state} handleClose={hideModal} />
		</div>
	);
}

export default PasswordReset;

PasswordReset.propTypes = {
	resPassword: PropTypes.func.isRequired
};
