import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';

const ModalPage = ({ modal, handleClose }) => {
	const showHideClassName = modal.show ? "modal display-block" : "modal display-none";

	return (
		<Modal open={modal.show} >
			<Box sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: 400,
					bgcolor: 'background.paper',
					border: '2px solid #E84927',
					borderRadius: '10px',
					boxShadow: 24,
					p: 4,
			}}>
				<Alert severity={modal.type}>{modal.msg}</Alert>
				<Grid container>
					<Grid item xs> </Grid>
					<Grid item>
						<Button
							variant="contained"
							type="button"
							sx={{	mt: 5, mb: 1,
									backgroundColor: '#E84927',
									'&:hover': { backgroundColor: '#B84927' },
							}}
							onClick={handleClose}
						>
							Fechar
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Modal>
	);
};

//onClose={handleClose}
export default ModalPage;
