import React, { useState } from 'react';
//import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress'

import config from '../config/config.js';
import logo from '../imgs/laboralogo.png';
import '../Style.css';

import PasswordReset from './PasswordReset';
import Modal from './ModalPage';

function Login({ setCampos }){

	const urlUserLogin = `${config.baseUrl}/${config.resrc.login}`;
	const [state, setState] = useState({ loading: false, show: false, msg: '', type: 'info' });
	const hideModal = () => { setState({ show: false, loading: false }) };

	const [email, setEmail] = useState();
	const [password, setPassword] = useState();

	function handleFormSubmit(event){
		event.preventDefault();
		setState({ loading: true });
		axios.post(urlUserLogin, {email, password} ).then(response => {
			if(response.status === 200){
				const data = {
					email: response.data.user.email,
					auth: response.data.auth,
					token: response.data.token,
					userId: response.data.user.id,
					//fullName: response.data.user.fullName,
					fullName: response.data.user.full_name,
					agency: response.data.user.agency,
					photo: response.data.user.photo,
					//tempPassword: response.data.user.tempPassword,
					tempPassword: response.data.user.is_temp_password,
				}
				//console.log(data);
				setCampos(data);
			}else{
				//alert(`Status: ${response.status}.\nUsuário ou Senha inválidos.`);
				setState({ loading: false, show: true, type: 'warning', msg: `Usuário ou Senha inválidos.` });
			}
		})
		.catch(() => {
			//alert(`Usuário ou Senha inválidos.`);
			setState({ loading: false, show: true, type: 'warning', msg: `Usuário ou Senha inválidos.` });
		})
	}

	const [loginData, resPassword] = useState({
		resetPass: false,
	});
	function goToRestorePass(){
		const data = { resetPass: true }
		resPassword(data);
		//console.log(`Restaurar Senha ${loginData.resetPass}.`);
	}

	if(loginData.resetPass){
		//console.log('Vai para pagina de reset passqord.');
		return (<div><PasswordReset resPassword={resPassword}/></div>);
	}
	
	if(state.loading) return (<div><CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%' }}/></div>);

	return (
		<div className="container">
			<img src={logo} className="Labora-logo" alt="logo" />
			<h4>"Bem vindo a Labora!"</h4>

			<form onSubmit={handleFormSubmit} >
				<fieldset>
					<legend> <h4>Efetuar Login:</h4> </legend>

					<div>
						<label htmlFor="e-mail">E-mail:</label>
						<input type="text" id="email" name="email" placeholder="E-mail do usuário.." onChange={e => setEmail(e.target.value)} />

						<label htmlFor="senha">Senha:</label>
						<input type="password" id="password" name="password" placeholder="Senha pessoal..." onChange={e => setPassword(e.target.value)} />
					</div>
					<br/>

					<div className='inline'>
						<input className='green-button' type="submit" value="Logar" />
					</div>

					<div className='center'>
						<a className='clicavel' onClick={goToRestorePass} >Esqueci a Senha</a>
					</div>
					{/* <br/>
					<br/> */}

				</fieldset>
			</form>
			<Modal modal={state} handleClose={hideModal} />
		</div>
	);
}

export default Login;

Login.propTypes = {
	setCampos: PropTypes.func.isRequired
};
