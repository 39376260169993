import React, { useState } from 'react';
//import { useNavigate} from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';

import config from '../config/config.js';
import logo from '../imgs/laboralogo.png';
import '../Style.css';
import Modal from './ModalPage';

function PasswordTemporario({ setCampos, getCampos }){

	//let navigate = useNavigate(); 
	//const routeToHome = () => navigate('/');
	const urlUpdateTempPass = `${config.baseUrl}/${config.resrc.updateTempPass}`;

	const [state, setState] = useState({ loading: false, show: false, msg: '', type: 'info' });
	const hideModal = () => { setState({ show: false, loading: false }) };

	const [newPassword, setPassword] = useState();
	const [confPassword, setConfPass] = useState();

	function handleFormSubmit(event){
		event.preventDefault();
		setState({ loading: true });
		if((newPassword === confPassword) && (newPassword)){
			const uData = getCampos();
			const data = {newPassword: newPassword}
			axios.put(urlUpdateTempPass, data, {headers: {'x-access-token': uData.token}}).then(response => {
				//alert('Senha alterada com sucesso.\nFaça Login para continuar.');
				setState({ loading: false, show: true, type: 'success', msg: `Senha alterada com sucesso.\nFaça Login para continuar.` });
				const clearData = {
					email: '',
					auth: false,
					token: '',
					userId: '',
					fullName: '',
					agency: [],
					photo: '',
					tempPassword: false,
				}
				setCampos(clearData);
			})
			.catch(() => {
				//alert(`Erro: Falha do servidor.`);
				setState({ loading: false, show: true, type: 'warning', msg: `Erro: Falha do servidor.` });
			});
		}else{
			//alert('Erro\nA confirmação da nova senha foi digitada incorretamente.');
			setState({ loading: false, show: true, type: 'warning', msg: `Erro\nA confirmação da nova senha foi digitada incorretamente.` });
		}
	}

	function cancelar(event){
		event.preventDefault();
		const data = {
			email: '',
			auth: false,
			token: '',
			userId: '',
			fullName: '',
			agency: [],
			photo: '',
			tempPassword: false,
		}
		setCampos(data);
	}

	if(state.loading) return (<div><CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%' }}/></div>);

	return (
		<div className="container">
			<img src={logo} className="Labora-logo" alt="logo" />
			<h4>"Senha Temporaria!"</h4>

			<form className='form' onSubmit={handleFormSubmit} >
				<fieldset>
					<legend> <h4>Defina a Nova Senha:</h4> </legend>

					<div>
						<label htmlFor="senha">Senha:</label>
						<input type="password" id="password" name="password" placeholder="Senha pessoal..." onChange={e => setPassword(e.target.value)} />
						<label htmlFor="senha">Confirmação da Senha:</label>
						<input type="password" id="password" name="password" placeholder="Senha pessoal..." onChange={e => setConfPass(e.target.value)} />
					</div>
					<br/>

					<div className='inline'>
						<input className='red-button' type="button" value="Cancelar" onClick={cancelar} />
						<input className='green-button' type="submit" value="Salvar" />
					</div>

				</fieldset>
			</form>
			<Modal modal={state} handleClose={hideModal} />
		</div>
	);
}

export default PasswordTemporario;

PasswordTemporario.propTypes = {
	setCampos: PropTypes.func.isRequired,
	getCampos: PropTypes.func.isRequired
};
