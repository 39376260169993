//import React from 'react';
import React, { useState, useEffect } from 'react';

function Time () {

	const [selectedDate, handleDateChange] = useState(getDateTime());

	function getDateTime () {
		let data = new Date();
		let dia = String(data.getDate()).padStart(2, '0');
		let mes = String(data.getMonth() + 1).padStart(2, '0');
		let ano = data.getFullYear();
		let dataAtual = dia + '/' + mes + '/' + ano;
		let hor = String(data.getHours()).padStart(2, '0');
		let min = String(data.getMinutes()).padStart(2, '0');
		let sec = String(data.getSeconds()).padStart(2, '0');
		//let horaAtual = `${hor}:${min}:${sec}`;
		let horaAtual = `${hor}:${min}`;
		let dateTime = `${horaAtual}    ${dataAtual}`;
		//console.log(dateTime);
		//document.getElementById('txt').innerHTML=`${h}:${m}:${s}`;
		//setTimeout('getDateTime()', 500);
		//handleDateChange(dateTime);
		return dateTime;
	}

	useEffect(() => {
		const timerID = setInterval(() => tick(), 1000);
		return () => {
			clearInterval(timerID);
    	};
	}, []);

	const tick = () => {
		handleDateChange(getDateTime());
	};

	return (
		<div>
			<label htmlFor="nome">Horário:</label>
			{/* <input className='font' type="text" id="txt" name="nome" placeholder="Horário atual..." value={getDateTime()} disabled /> */}
			<input className='font' type="text" id="txt" name="nome" placeholder="Horário atual..." value={selectedDate} disabled />
			{/* <div className='div-inline'>Horário</div>
			<div className='div-inline' id="txt"></div> */}
		</div>
	);
}

export default Time;
